<template>
	<div id="ag-grid-demo">
		<div id="grid-loader" class="vx-card vs-con-loading__container p-6">
			<!-- TABLE ACTION ROW -->
			<ag-grid-vue
				:components="components"
				:gridOptions="gridOptions"
				class="ag-theme-material w-100 mb-4 ag-grid-table"
				:columnDefs="columnDefs"
				:defaultColDef="defaultColDef"
				:rowModelType="rowModelType"
				serverSideStoreType="partial"
				rowSelection="multiple"
				:enableRangeSelection="true"
				:suppressCopyRowsToClipboard="true"
				colResizeDefault="shift"
				:suppressDragLeaveHidesColumns="true"
				:animateRows="false"
				:floatingFilter="true"
				:pagination="true"
				:paginationPageSize="paginationPageSize"
				:suppressPaginationPanel="true"
				:suppressRowClickSelection="true"
				:rowClassRules="rowClassRules"
				:statusBar="statusBar"
				:sideBar="sideBar"
				:cacheBlockSize="1000"
				:rowHeight="38"
				:getContextMenuItems="getContextMenuItems"
				@grid-ready="onGridReady"
				@column-resized="onColumnResized"
				@row-selected="onRowSelected"
				@displayed-columns-changed="displayedColumnsChanged"
				@model-updated="modelUpdated"
				@filter-changed="filterChanged">
			</ag-grid-vue>
			<div class="vx-row">
				<div v-if="user_role == 'admin'" class="vx-col w-full md:w-1/2 flex justify-center md:justify-start">
					<vs-button color="dark" class="md:block" @click="gridSelectAll()">Select All</vs-button>
					<template v-if="selectedDomains.length">
						<vs-button class="md:block ml-4" @click="takedown()">Takedown</vs-button>
						<vs-button class="md:block ml-4" @click="saveWatchlistPrompt()" type="border" icon-pack="feather" icon="icon-eye"></vs-button>
						<vs-button class="hidden md:block ml-4" color="dark" type="border" @click="showCart = showCart ?  false : true">{{ selectedDomains.length }} Selected</vs-button>
					</template>
				</div>
				<div class="vx-col w-full" :class="{ 'md:w-1/2': user_role == 'admin' }">
					<vs-pagination
					:total="totalPages"
					:max="maxPageNumbers"
					v-model="currentPage" />
				</div>
			</div>
		</div>

		<new-registrations-cart v-if="showCart && selectedDomains.length" :cart-data="selectedDomains" :modified-column-state="modifiedColumnState" @rowUnselected="rowUnselected" @cartTakedown="takedown()"/>
		
		<!-- For Domain Preview Component -->
		<router-view></router-view>

		<vs-prompt id="watchlist-prompt" vs-title="Enter watchlist name" :vs-is-valid="watchlist['name'] != '' || watchlist['existing']['value'] != null" @vs-cancel="watchlist['name']=''" @vs-accept="saveWatchlist" :vs-active.sync="watchlist['prompt']">
			<div class="con-exemple-prompt text-center">
				<v-select class="w-full" placeholder="Select Watchlist" v-model="watchlist['existing']['value']" :options="watchlist['existing']['options']" :reset-on-options-change="true"></v-select>
				<p class="mb-1">or</p>
				<vs-input v-model="watchlist['name']" class="w-full" :disabled="watchlist['existing']['value'] != null"/>
			</div>
		</vs-prompt>
	</div>
</template>

<script>
	import { AgGridVue } from "ag-grid-vue"
	import "ag-grid-enterprise";
	import NewRegistrationsCart from '@/components/selected-domains-cart/NewRegistrationsCart.vue'
	import vSelect from 'vue-select'

	import '@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss'

	// Cell Renderer
	import CellRendererEncryptedEmail from '@/components/ag-grid-tables/cell-renderer/EncryptedEmailHyperlink.vue'
	import CellRendererEncryptedPhone from '@/components/ag-grid-tables/cell-renderer/EncryptedPhoneHyperlink.vue'

	export default {
		components: {
			AgGridVue,
			NewRegistrationsCart,
            'v-select': vSelect,
			CellRendererEncryptedEmail,
			CellRendererEncryptedPhone
		},
		data() {
			return {
				isGridReady: false,
				gridOptions: {},
				gridCount: 0,
				maxPageNumbers: 7,
				gridApi: null,
				defaultColDef: {
					sortable: true,
					resizable: true,
					suppressMenu: true,
					suppressMovable: false,
					filterParams: {
						suppressAndOrCondition: true,
						newRowsAction: 'keep'
					},
				},
				rowClassRules: null,
				statusBar: null,
				sideBar: null,
				rowModelType: "serverSide",
				columnDefs: [],
				modifiedColumnState: null,
				selectedDomains: [],
				showCart: false,
				selectAll: false,
				watchlist: {
					name: '',
					existing: {
						value: null,
						options: []
					},
					prompt: false
				},
				isBlacklistFilterApplied: false,

				// Cell Renderer Components
				components: {
					CellRendererEncryptedEmail,
					CellRendererEncryptedPhone
				},
			}
		},
		watch: {
			windowWidth(val) {
				// Toggle colummn movable
				this.toggleColumnMovable(val);
				// Toggle colummn pinning
				this.toggleColumnPinned(val);
			},
			tableFilterValue(val) {
				// Apply Filter Event
				this.applyFilter(val);
			},
			totalPages() {
				if(this.gridApi) {
					this.gridCount = this.gridApi.getDisplayedRowCount() - 1
				}
				else {
					this.gridCount = 0
				}
			},
			modifiedColumnState: {
				handler: function(val) {
					localStorage.setItem('col-'+this.$route.name, JSON.stringify({'state': val}));
				},
				deep: true
			},
			'watchlist.existing.value'(val) {
				if(val) {
					this.watchlist['name'] = '';
				}
			},
		},
		computed: {
			windowWidth: function(){
				return this.$store.state.windowWidth;
			},
			blacklistedKeywords: function(){
				return this.$store.getters.getBlacklistedKeywords;
			},
			currentTimestamp: function(){
				return this.$store.getters.getCurrentTimestamp;
			},
			formattedTimestamp: function(){
				return this.currentTimestamp != null ? new Date(this.currentTimestamp) : this.currentTimestamp;
			},
			paginationPageSize() {
				if(this.gridApi) {
					return this.gridApi.paginationGetPageSize()
				}
				else {
					return 1000
				}
			},
			totalPages() {
				if(this.gridApi) {
					return this.gridApi.paginationGetTotalPages()
				}
				else {
					return 0
				}
			},
			currentPage: {
				get() {
					if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
						else return 1
					},
				set(val) {
					this.gridApi.paginationGoToPage(val - 1);
				}
			},
			tableFilterValue() {
				return this.$store.getters.getTableFilterValue;
			},
			anomalyFilter: function(){
				return this.$store.getters.getAnomalyFilter;
			},
			anomalyFilterModel() {
				if (this.anomalyFilter) {
					let filterObj = {};

					if (this.anomalyFilter['tld']) {
						filterObj['tld'] = {
							type: 'set',
							values: [this.anomalyFilter['tld']]
						}
					}
					if (this.anomalyFilter['registrar_id']) {
						filterObj['registrar_id'] = {
							type: 'contains',
							filter: this.anomalyFilter['registrar_id']
						}
					}
					if (this.anomalyFilter['transaction_date']) {
						filterObj['transaction_date'] = {
							filterType: 'multi',
							filterModels: [
								{
									filterType: 'text',
									type: 'contains',
									filter: this.anomalyFilter['transaction_date']
								},
								null
							]
						}
					}
					if (this.anomalyFilter['suspicion_level']) {
						filterObj['suspicion_level'] = {
							type: 'set',
							values: [this.anomalyFilter['suspicion_level']]
						}
					}
					if (this.anomalyFilter['reseller']) {
						filterObj['reseller'] = {
							type: 'contains',
							filter: this.anomalyFilter['reseller']
						}
					}
					if (this.anomalyFilter['daily_pattern_cluster_id']) {
						filterObj['daily_pattern_cluster_id'] = {
							type: 'equals',
							filter: this.anomalyFilter['daily_pattern_cluster_id']
						}
					}

					return filterObj;
				}
				else {
					return null;
				}
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showDivLoading(){
				this.$vs.loading({
					container: '#grid-loader',
					scale: 0.45
				});
			},
			hideDivLoading(){
				this.$vs.loading.close("#grid-loader > .con-vs-loading");
			},
			displayedColumnsChanged() {
				if (this.isGridReady) {
					this.modifiedColumnState = this.gridOptions.columnApi.getColumnState();
				}
			},
			toggleColumnMovable(val) {
				if(val <= 576) {
					this.defaultColDef['suppressMovable'] = true;
				}
				else {
					this.defaultColDef['suppressMovable'] = false;
				}
			},
			onColumnResized() {
				this.gridApi.resetRowHeights();
			},
			sizeColumnsToFit() {
				this.gridApi.sizeColumnsToFit();
			},
			onGridReady(params) {
				let self = this;

				// const endpoint = `${this.$SERVERSIDE}/node-new-registrations/`;
				const endpoint = 'https://mirador.watchtower.space/mirador-new-registrations/';

				// Set Data Source
				params.api.setServerSideDatasource({
					getRows(params) {
						fetch(endpoint, {
							method: 'post',
							body: JSON.stringify(params.request),
							headers: {"Content-Type": "application/json; charset=utf-8"}
						})
						.then(httpResponse => httpResponse.json())
						.then(response => {
							if(response.lastRow) {
								// clear all overlays
								self.gridApi.hideOverlay();
								params.successCallback(response.rows, response.lastRow);
							}
							else {
								// clear all overlays
								self.gridApi.hideOverlay();
								// show 'no rows' overlay
								self.gridApi.showNoRowsOverlay();
								params.successCallback([], 0);
							}
							// Set displayed row count
							self.$store.dispatch("setListingFilteredCount", self.gridApi.getDisplayedRowCount());

							// Set Column State
							if (!self.isGridReady) {
								let columnState = JSON.parse(localStorage.getItem('col-'+self.$route.name));
								if(columnState) {
									self.gridOptions.columnApi.setColumnState(columnState.state);
								}
							}
							
							// Set isGridReady Flag
							self.isGridReady = true;
						})
						.catch(error => {
							console.error(error);
							params.failCallback();
						})
					}
				});
			},
			getUrlParameter(name) {
				return this.$route.query[name];
			},
			filterBlacklistedDomains(flag) {
				// Get a reference to the domain filter instance
				let domainFilterInstance = this.gridApi.getFilterInstance('domain');

				// Set the model for the filter
				if(flag) {
					domainFilterInstance.setModel({
						type: 'regex',
						filter: this.blacklistedKeywords.map(x => x['keyword']).join('|')
					});
				}
				else {
					domainFilterInstance.setModel(null);
				}

				// Get grid to run filter operation again
				this.gridApi.onFilterChanged();
			},
			toggleDomainFilterLock(flag) {
				if(flag) {
					document.querySelector(".ag-pinned-left-header .ag-floating-filter-body").style.pointerEvents = "none";
					document.querySelector(".ag-pinned-left-header .ag-floating-filter-button").style.pointerEvents = "none";
				}
				else {
					document.querySelector(".ag-pinned-left-header .ag-floating-filter-body").style.pointerEvents = null;
					document.querySelector(".ag-pinned-left-header .ag-floating-filter-button").style.pointerEvents = null;
				}
			},
			applyAnomalyFilter(model) {
				if(model) {
					this.gridApi.setFilterModel(model);
					this.gridApi.onFilterChanged();
				}
			},
			applyFilter(val) {
				if(val) {
					this.gridApi.setFilterModel(val['value']);
					this.gridApi.onFilterChanged();
				}
				else {
					this.gridApi.setFilterModel(null);
					this.gridApi.onFilterChanged();
				}
			},
			filterChanged() {
				let filter = this.gridApi.getFilterModel();
				
				if(Object.keys(filter).length) {
					this.$store.dispatch("setTableFilterActive");
					
					// // If Blacklisted Filter is applied on Domains
					// if(filter['domain'] && filter['domain']['type'] == 'blacklisted') {
					// 	// Disable filter UI
					// 	this.toggleDomainFilterLock(true);
					// }
					// else {
					// 	// Enable filter UI
					// 	this.toggleDomainFilterLock(false);
					// }
				}
				else {
					this.$store.dispatch("clearTableFilterActive");

					// // Enable filter UI
					// this.toggleDomainFilterLock(false);
				}
			},
			modelUpdated() {
				// Iterate each node
				this.gridApi.forEachNode((node) => {
					if(node['data'] && this.selectedDomains.length) {
						let domainIDs = this.selectedDomains.map(x => x['domain_id']);
						// Check if node is in selectedDomains
						if(domainIDs.includes(node['data']['domain_id'])) {
							node.setSelected(true);
						}
						else {
							node.setSelected(false);
						}
					}
				});
				// Reset selectAll flag
				this.selectAll = false;
			},
			onRowSelected(event) {
				if(event.node.selected) {
					// Push to selectedDomains
					this.pushToArray(this.selectedDomains, event.node.data, 'domain_id');
				}
				else {
					// Remove from selectedDomains
					this.removeFromArray(this.selectedDomains, event.node.data, 'domain_id');
				}
			},
			rowUnselected(data) {
				this.gridApi.forEachNode((node) => {
					// Unselect the node with passed id
					if (node['data']['domain_id'] == data.domain_id) {
						node.setSelected(false);
					}
				});
				// Remove from selectedDomains
				this.removeFromArray(this.selectedDomains, data, 'domain_id');
			},
			takedown() {
				// Save takedown domains
				this.$store.dispatch("setTakedownDomains", this.selectedDomains.map(x => {
					return {
						domain: x['domain'],
						domain_id: x['domain_id'],
						tld: x['tld']
					};
				}));
				// Redirect to listing
				this.$router.push({ path: '/suspension' })
			},
			populateWatchlistData() {
				this.$axiosSecure.get('/get-watch-lists-json')
				.then((response) => {
					// Get Existing Watchlist Options API call
					this.watchlist['existing']['options'] = response.data.map(x => {
						return {
							label: x['watch_list_name'],
							value: x['id']
						}
					});
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			saveWatchlistPrompt() {
				// Get Existing Watchlist Options
				this.populateWatchlistData();
				// Assign below on AXIOS success
				this.watchlist['name'] = '';
				this.watchlist['existing']['value'] = null;
				this.watchlist['prompt'] = true;
				this.watchlist['title'] = '';
			},
			saveWatchlist() {
				let self = this,
					watchlistData;
				// Show loader
				this.showDivLoading();
				
				if(this.watchlist['existing']['value']) {
					watchlistData = {
						table: 'new_registrations',
						id: this.watchlist['existing']['value']['value'],
						domains: this.selectedDomains.map(x => x['domain_id'])
					}
				}
				else {
					watchlistData = {
						table: 'new_registrations',
						name: this.watchlist['name'],
						domains: this.selectedDomains.map(x => x['domain_id'])
					}
				}

				// Save Watchlist Axios Call
				this.$axiosSecure.post('/save-watch-list', {
					...watchlistData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Watchlist save success',
							text: 'The selected domains have been saved to watchlist'
						});
						// Hide loader
						this.hideDivLoading();
						// Get Existing Watchlist Options
						this.populateWatchlistData();
					}
					else if(success.data == "updated") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Watchlist update success',
							text: 'The selected domains have been added to watchlist'
						});
						// Hide loader
						this.hideDivLoading();
					}
					else if(success.data == "missing") {
						// Confimation notification
						self.$vs.notify({
							color: 'warning',
							title: 'Watchlist save error',
							text: 'The selected watchlist does not exist'
						});
						// Hide loader
						this.hideDivLoading();
					}
					else {
						// Duplicate notification
						self.$vs.notify({
							color: 'warning',
							title: 'Watchlist save error',
							text: `The selected watchlist is a duplicate of "${success.data.name}"`
						});
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			gridSelectAll() {
				this.gridApi.forEachNode((node) => {
					if(this.selectAll) {
						// Unselect the node
						node.setSelected(false);
						// Remove from selectedDomains
						this.removeFromArray(this.selectedDomains, node.data, 'domain_id');
					}
					else {
						// Select the node
						node.setSelected(true);
					}
				});
				// Toggle selectAll flag
				this.selectAll = !this.selectAll;
			},
			getContextMenuItems(params) {
				let result,
					self = this;

				if(params['column'] && params['column']['colId'] && params['column']['colId'] == 'domain') {
					result = [
						{
							name: "View Domain Details",
							action: function() {
								let routeData = self.$router.resolve({name: 'search', query: {domain_name: params.value}});
								window.open(routeData.href, '_blank');
							},
						},
						'separator',
						{
							name: "Resolve Domain(s)",
							action: function() {
								let rangeSelection = self.gridApi.getCellRanges(),
									value = params['value'];

								if(rangeSelection) {
									// Get selected cells & iterate through them to find domains
									rangeSelection.forEach(item => {
										let start = Math.min(item.startRow.rowIndex, item.endRow.rowIndex),
											end = Math.max(item.startRow.rowIndex, item.endRow.rowIndex),
											isRightClicked = false;
										
										for (let i = start; i <= end; i++) {
											if(self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'] == value) {
												isRightClicked = true
											}
											window.open('https://anonymto.com/?http://' + self.gridApi.getDisplayedRowAtIndex(i)['data']['domain'], '_blank');
										}
										
										if(!isRightClicked) {
											window.open('https://anonymto.com/?http://' + value);
										}
									});
								}
								else {
									window.open('https://anonymto.com/?http://' + value);
								}
							},
						},
						'separator',
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}
				else {
					result = [
						'copy',
						'copyWithHeaders',
						'paste',
						'separator',
						'export'
					];
				}

				return result;
			},
			populateQuickFilterData(loader) {
				this.$axiosSecure.get('/get-filters-json', {
					params: {
						table: 'new_registrations'
					}
				})
				.then((response) => {
					// Set Filter Options
					this.$store.dispatch("setTableFilterOptions", response.data.map(x => {
						return {
							label: x['filter_name'],
							value: JSON.parse(x['filter_json'])
						};
					}));
				
					if(loader == 'loader') {
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
		},
		beforeMount() {
			// Reset movable columns on mobile
			this.toggleColumnMovable(this.windowWidth);

			// Set Grid sidebar
			this.sideBar = {
				toolPanels: [
				{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel",
					toolPanelParams: {
						suppressRowGroups: true,
						suppressValues: true,
						suppressPivots: true,
						suppressPivotMode: true,
						suppressSideButtons: true,
						suppressColumnSelectAll: true,
						suppressColumnExpandAll: true
					}
				}
				],
			};

			// Set Grid columnDefs
			this.columnDefs = [
			{
				headerName: 'Domain',
				field: 'domain',
				minWidth: 275,
				filter: "agTextColumnFilter",
				filterParams: {
					filterOptions: ["equals", "notEqual", "startsWith", "endsWith", "contains", "notContains", {
						displayKey: 'regex',
						displayName: 'Multiple contains',
						test: function(filterValue, cellValue) {
							return cellValue;
						},
					}, {
						displayKey: 'pattern',
						displayName: 'Pattern',
						test: function(filterValue, cellValue) {
							return cellValue;
						},
					}],
					defaultOption: 'pattern',
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					debounceMs: 1000
				},
				pinned: 'left',
				cellRenderer: (data) => {
					if(data.value) {
						let route = {
							name: "new-registrations-domain-preview",
							params: { id: data.node.data.domain_id }
						};

						let linkText = data.value;
						this.blacklistedKeywords.forEach((word) => {
							switch (word.category) {
								case 'brand':
									linkText = linkText.replace(word.keyword, `<strong class="text-danger">${word.keyword}</strong>`);	
									break;
								default:
									linkText = linkText.replace(word.keyword, `<strong class="text-warning">${word.keyword}</strong>`);
									break;
							}
						});

						let link = document.createElement("a");
						link.href = this.$router.resolve(route).href;
						link.innerHTML = linkText;
						link.addEventListener("click", e => {
							e.preventDefault();
							this.$router.push(route);
						});

						return link;
					}
					else {
						return ''
					}
				},
				checkboxSelection: JSON.parse(localStorage.getItem('userDetails')).role == 'admin',
			},
			{
				headerName: 'Domain ID',
				field: 'domain_id',
				filter: "agTextColumnFilter",
				minWidth: 150,
				hide: true,
			},
			{
				headerName: 'TLD',
				field: 'tld',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: ['site','website','store','online','tech','press','host','fun','space','uno','pw','in.net'],
					suppressMiniFilter: true,
				},
				minWidth: 100,
				width: 100,
			},
			{
				headerName: 'SLD',
				field: 'sld',
				filter: "agTextColumnFilter",
				filterParams: {
					filterOptions: ["equals", "notEqual", "startsWith", "endsWith", "contains", "notContains", {
						displayKey: 'pattern',
						displayName: 'Pattern',
						test: function(filterValue, cellValue) {
							return cellValue;
						}
					}],
					defaultOption: 'pattern',
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					debounceMs: 1000
				},
				minWidth: 150,
				hide: true,
			},
			{
				headerName: 'Status',
				field: 'status',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: ['A','SH','SHP','SHF','PD','D'],
					suppressMiniFilter: true,
				},
				cellRenderer: (data) => {
					if(data.value) {
						switch (data.value) {
							case 'A':
								return `<div class="badge badge--success inline">${data.value}</div>`
							case 'SH':
								return `<div class="badge badge--danger inline">${data.value}</div>`
							case 'SHP':
								return `<div class="badge badge--warning inline">${data.value}</div>`
							case 'SHF':
								return `<div class="badge badge--warning inline">${data.value}</div>`
							case 'PD':
								return `<div class="badge badge--info inline">${data.value}</div>`
							case 'D':
								return `<div class="badge badge--dark inline">${data.value}</div>`
							default:
								return `<div>-</div>`
						}
					}
					else {
						return ''
					}
				},
				minWidth: 100,
			},
			{
				headerName: 'Client Hold',
				field: 'client_hold',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Unsuspended',
				field: 'unsuspended',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Blacklisted Registrant',
				field: 'blacklisted_registrant',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'DGA',
				field: 'dga',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: ['chaes', 'flubot', 'gozi', 'infy', 'locky', 'mirai', 'necurs', 'nymaim', 'padcrypt', 'qsnatch', 'ranbyus', 'sharkbot', 'tinba', '-'],
					suppressMiniFilter: true,
				},
				cellRenderer: (data) => {
					if(data.value) {
						return `<div class="badge badge--dark inline">${data.value}</div>`
					}
					else {
						return ''
					}
				},
				minWidth: 200,
			},
			{
				headerName: 'DGA Regex',
				field: 'dga_regex',
				filter: "agTextColumnFilter",
				minWidth: 275,
			},
			{
				headerName: 'Transaction Date',
				field: 'transaction_date',
				// filter: "agTextColumnFilter",
				filter: 'agMultiColumnFilter',
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								newRowsAction: 'keep',
							}
						},
						{
							filter: 'agDateColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								defaultOption: 'inRange',
								newRowsAction: 'keep',
							}
						},
					],
				},
				minWidth: 235,
				cellRenderer: (data) => {
					if(data.value) {
						return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
					}
					else {
						return ''
					}
				},
				sort: 'desc',
			},
			{
				headerName: 'Registrar ID',
				field: 'registrar_id',
				filter: "agTextColumnFilter",
				minWidth: 150,
			},
			{
				headerName: 'Registrar',
				field: 'registrar_organization',
				filter: "agTextColumnFilter",
				minWidth: 275,
			},
			{
				headerName: 'Period',
				field: 'period',
				filter: "agTextColumnFilter",
				minWidth: 150,
				hide: true,
			},
			{
				headerName: 'Amount Gross Price',
				field: 'amount_gross_price',
				filter: "agTextColumnFilter",
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Grace Period Ends',
				field: 'grace_period_ends',
				// filter: "agTextColumnFilter",
				filter: 'agMultiColumnFilter',
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								newRowsAction: 'keep',
							}
						},
						{
							filter: 'agDateColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								defaultOption: 'inRange',
								newRowsAction: 'keep',
							}
						},
					],
				},
				minWidth: 235,
				hide: true,
				cellRenderer: (data) => {
					if(data.value) {
						return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
					}
					else {
						return ''
					}
				}
			},
			{
				headerName: 'Promotion ID',
				field: 'promotion_id',
				filter: "agTextColumnFilter",
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Registrant Email',
				field: 'registrant_email',
				filter: "agTextColumnFilter",
				filterParams: {
					filterOptions: ["equals", "notEqual", "startsWith", "endsWith", "contains", "notContains", {
						displayKey: 'regex',
						displayName: 'Multiple contains',
						test: function(filterValue, cellValue) {
							return cellValue;
						},
					}, {
						displayKey: 'pattern',
						displayName: 'Pattern',
						test: function(filterValue, cellValue) {
							return cellValue;
						}
					}],
					defaultOption: 'pattern',
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					debounceMs: 1000
				},
				cellRendererFramework: 'CellRendererEncryptedEmail',
				minWidth: 200,
			},
			{
				headerName: 'Nameservers',
				field: 'nameservers',
				filter: "agTextColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Reported',
				field: 'reported',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Reseller',
				field: 'reseller',
				filter: "agTextColumnFilter",
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Whitelisted',
				field: 'whitelisted',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Locked',
				field: 'locked',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Spike',
				field: 'is_spike',
				filter: "agSetColumnFilter",
				filterParams: {
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					values: [1,0],
					suppressMiniFilter: true,
					cellRenderer: (data) => {
						if (parseInt(data.value) === 0) {
							return 'no';
						}
						else if (parseInt(data.value) === 1) {
							return 'yes';
						}
						else {
							return data.value;
						}
					}
				},
				minWidth: 150,
			},
			{
				headerName: 'Hour Domain',
				field: 'hour_domain',
				filter: "agNumberColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'APM Score',
				field: 'apm_score',
				filter: "agNumberColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Proximity Score',
				field: 'proximity_score',
				filter: "agNumberColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Daily Pattern',
				field: 'daily_pattern',
				filter: "agTextColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Daily Pattern Cluster ID',
				field: 'daily_pattern_cluster_id',
				filter: "agTextColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Daily Pattern Domain Count',
				field: 'daily_pattern_domain_count',
				filter: "agNumberColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Gibberish Score',
				field: 'gibberish_score',
				filter: "agNumberColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Registrant ID',
				field: 'registrant_id',
				filter: "agTextColumnFilter",
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Registrant Phone',
				field: 'registrant_phone_voice',
				filter: "agTextColumnFilter",
				filterParams: {
					filterOptions: ["equals", "notEqual", "startsWith", "endsWith", "contains", "notContains", {
						displayKey: 'pattern',
						displayName: 'Pattern',
						test: function(filterValue, cellValue) {
							return cellValue;
						}
					}],
					defaultOption: 'pattern',
					suppressAndOrCondition: true,
					newRowsAction: 'keep',
					debounceMs: 1000
				},
				cellRendererFramework: 'CellRendererEncryptedPhone',
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Registrant State',
				field: 'registrant_state',
				filter: "agTextColumnFilter",
				minWidth: 200,
				hide: true,
			},
			{
				headerName: 'Registrant Country',
				field: 'registrant_country',
				filter: "agTextColumnFilter",
				minWidth: 200,
			},
			{
				headerName: 'Created At',
				field: 'created_at',
				// filter: "agTextColumnFilter",
				filter: 'agMultiColumnFilter',
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								newRowsAction: 'keep',
							}
						},
						{
							filter: 'agDateColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								defaultOption: 'inRange',
								newRowsAction: 'keep',
							}
						},
					],
				},
				minWidth: 235,
				cellRenderer: (data) => {
					if(data.value) {
						return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
					}
					else {
						return ''
					}
				},
			},
			{
				headerName: 'Updated At',
				field: 'updated_at',
				// filter: "agTextColumnFilter",
				filter: 'agMultiColumnFilter',
				filterParams: {
					filters: [
						{
							filter: 'agTextColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								newRowsAction: 'keep',
							}
						},
						{
							filter: 'agDateColumnFilter',
							filterParams: {
								suppressAndOrCondition: true,
								defaultOption: 'inRange',
								newRowsAction: 'keep',
							}
						},
					],
				},
				minWidth: 235,
				hide: true,
				cellRenderer: (data) => {
					if(data.value) {
						return this.$moment.utc(data.value).format('DD-MM-YYYY HH:mm:ss')
					}
					else {
						return ''
					}
				}
			},
			]
		},
		mounted() {
			this.gridApi = this.gridOptions.api;

			// Filter if Query String is present
			if(this.getUrlParameter('filter')) {
				if(this.anomalyFilter) {
					this.applyAnomalyFilter(this.anomalyFilterModel);
				}
				else {
					this.$router.replace('/new-registrations');
				}
			}
			else {
				// Clear Anomaly Filter
				this.$store.dispatch("clearAnomalyFilter");
			}

			// Resize to fit columns
			this.sizeColumnsToFit();

			// Get Quick Filter Options
			this.populateQuickFilterData();

			// Page Refresh Event
			this.$root.$on('refreshPage', () => {
				// Reset filters
				this.gridApi.setFilterModel(null);
				// Reset selected domains
				this.selectedDomains = [];
				// Reset selection
				this.gridApi.deselectAll();
				// Reset sort
				this.gridApi.setSortModel([
					{
					colId: "transaction_date",
					sort: "desc"
					}
				]);
				// Clear Table Filter
				this.$store.dispatch("clearTableFilterValue");
			});

			// Page Download Event
			this.$root.$on('downloadPage', () => {
				// AgGrid CSV Export
				this.gridApi.exportDataAsCsv()
			});

			// Apply Filter Event
			if(this.anomalyFilter == null) {
				if(this.tableFilterValue) {
					this.applyFilter(this.tableFilterValue);
				}
				else {
					this.applyFilter(null);
				}
			}

			// Blacklisted Filter Event
			this.$root.$on('blacklistedFilter', (flag) => {
				this.filterBlacklistedDomains(flag);
			});

			// Save Filter Event
			this.$root.$on('saveFilter', (title) => {
				let self = this;
				// Show loader
				this.showDivLoading();
				// Save Filter Axios Call
				this.$axiosSecure.post('/save-filter', {
					table: 'new_registrations',
					filter: {
						label: title,
						value: self.gridApi.getFilterModel()
					},
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((success) => {
					if(success.data == "created") {
						// Confimation notification
						self.$vs.notify({
							color: 'success',
							title: 'Filter save success',
							text: 'The selected filter has been saved'
						});
						
						// Get Filter AXIOS Call
						this.populateQuickFilterData('loader');
					}
					else {
						// Duplicate notification
						self.$vs.notify({
							color: 'warning',
							title: 'Filter save error',
							text: `The selected filter is a duplicate of "${success.data.filter_name}"`
						});
						// Hide loader
						this.hideDivLoading();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideDivLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			});
			
			// Get Listing Count
			this.$axiosSecure.get('/new-registrations-count')
			.then((response) => {
				// Set Listing Count
				this.$store.dispatch("setListingTotalCount", response.data);
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
				
				// Clear Listing Count
				this.$store.dispatch("clearListingCount");
			});

		},
		destroyed() {
			// Clear Listing Count
			this.$store.dispatch("clearListingCount");

			// Clear Table Filter
            this.$store.dispatch("clearTableFilterValue");
			this.$store.dispatch("clearTableFilterOptions");
			this.$store.dispatch("clearTableFilterActive");
			
			// Unbind Button Event Listeners
			this.$root.$off('refreshPage');
			this.$root.$off('downloadPage');
			this.$root.$off('saveFilter');
			this.$root.$off('blacklistedFilter');
		}
	}

</script>